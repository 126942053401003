import React, { useMemo, useContext, CSSProperties } from 'react';
import Tippy from '@tippyjs/react';
import { nanoid } from 'nanoid';
import { SvgIcon } from 'general/js/svg-icon';
import classNames from 'classnames';
import {
    IShareBtn,
    IShareDirection,
    IResolvePlacement,
    IPlacement,
} from 'components/share-btn/js/types';
import { ShareList } from 'components/share/js/share-list';
import { GenerateShareItemsByNames } from 'components/share/js/generate-share-items-by-names';
import { PopupWrapper, PopupWrapperContext } from 'components/popup/js/popup-wrapper';
//import { useStore } from 'effector-react';
// import { isTouchStore } from 'general/js/touch-switcher';
import { operators } from '@deleteagency/device-observer/src/index';
import { deviceObserver } from 'general/js/device-observer';

const resolveDirection = (axis: IShareDirection) => (axis === 'y' ? 'top' : 'left');

const resolvePlacement = ({ mobileDirection, direction }: IResolvePlacement): IPlacement => {
    const isMobile = deviceObserver.is(operators.less, 'desktop');
    return isMobile ? resolveDirection(mobileDirection) : resolveDirection(direction);
};

const Component = ({
    direction = 'x',
    mobileDirection = 'y',
    downloadLink,
    sharedLink,
    embedText,
    shareElements = ['facebook', 'linkedin', 'twitter'],
    mode,
    emailLink,
    size,
}: IShareBtn): JSX.Element => {
    const { close, isActive, onShow } = useContext(PopupWrapperContext);
    const ShareStyles = { '--share-btn-popup-z-index': 10 } as CSSProperties;
    const shareItems = GenerateShareItemsByNames({
        embedText,
        downloadLink,
        sharedLink,
        emailLink,
        shareNames: shareElements,
    });
    //const isTouch = useStore(isTouchStore);
    const placement = resolvePlacement({ mobileDirection, direction });
    
    return (
        <div
            className={classNames(
                'share-btn',
                mode && `share-btn--${mode}`,
                isActive && 'share-btn--active',
                size && `share-btn--${size}`
            )}
            style={ShareStyles}
        >
            <Tippy
                duration={[300, 100]}
                placement={placement}
                animation="fade-in"
                interactive={true}
                interactiveBorder={10}
                trigger={'click'}
                hideOnClick={true}
                arrow={false}
                offset={[0, 0]}
                maxWidth="100%"
                // @ts-ignore
                appendTo={(ref) => {
                    // Render on section to prevent bug
                    // when tippy renders inside positioned element
                    // and it's the last sectoin of the page
                    const section = ref.closest('.section');
                    return section || ref || document.body;
                }}
                zIndex={10}
                onShow={onShow}
                onHidden={close}
                content={
                    <ShareList
                        shareItems={shareItems}
                        downloadLink={downloadLink}
                        sharedLink={sharedLink}
                        emailLink={emailLink}
                        baseClassName="share-popup-list"
                        className={size && `share-popup-list--${size}`}
                    />
                }
                popperOptions={{
                    modifiers: [
                        {
                            name: 'preventOverflow',
                            options: {
                                mainAxis: false,
                            },
                        },
                    ],
                }}
            >
                <div className="share-btn__wrapper">
                    <a
                        className="share-btn__toggle-btn"
                        aria-label={`${isActive ? 'close' : 'open'} share menu`}
                        title="Share"
                    >
                        <SvgIcon size={[29, 29]} iconName="share" />
                        <span className="share-btn__btn-text">Share</span>
                    </a>
                </div>
            </Tippy>
        </div>
    );
};

export const ShareBtn = (props: IShareBtn) => {
    const id = useMemo(() => nanoid(10), []);

    return (
        <PopupWrapper id={id}>
            <Component {...props} />
        </PopupWrapper>
    );
};
