/* general styles */
import 'general/scss/index.scss';

/* plugins */
import 'general/js/what-input';

/* components */
import 'components/lazysizes';
import 'components/_footer';
import 'components/_sidebar';
import 'components/richtext';
import 'components/error-404';
import 'components/article-layout';
import 'components/breadcrumbs';
import 'components/share-mobile';
import 'components/tags';
import 'components/animate';
import 'components/tag-page';
import 'components/article-card';
import 'components/article-cards-grid';
import 'components/caption';
import 'components/top-nav';
import 'components/quote-block';
import 'components/burger-menu';
import 'components/modal';
import 'components/generic-slider';
import 'components/generic-card';
import 'components/section';
import 'components/carousel-filter';
import 'components/image-gallery';
import 'components/article-preview';
import 'components/video';
import 'components/spinner';
import 'components/home-page';
import 'components/cta-block';
import 'components/text-block';
import 'components/form-brics/input';
import 'components/form-brics/checkbox';
import 'components/form-brics/select';
import 'components/form';
import 'components/select-spec';
import 'components/form-validate';
import 'components/share-btn';
import 'components/share-list';
import 'components/image-blocks';
import 'components/articles-list';

/* require svg */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const files = require.context('general/svg', true, /^\.\/.*\.svg/);
files.keys().forEach(files);
