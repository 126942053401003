import { DcBaseComponent } from '@deleteagency/dc';
import { IDCRefsCollection } from '@deleteagency/dc/src/dc-dom';

interface IRefs extends IDCRefsCollection {
    goBack: HTMLElement;
}

class Error404Component extends DcBaseComponent<HTMLDivElement, void, IRefs> {
    static namespace = 'error-404';

    static requiredRefs = ['goBack'];

    init() {
        this.addListener(this.refs.goBack, 'click', this._onGoBackClick);
    }

    _onGoBackClick = () => {
        window.history.go(-2);
        return false;
    };
}

export default Error404Component;
