import { createElement } from 'react';
import { render } from 'react-dom';
import { DcBaseComponent } from '@deleteagency/dc';
import { IDCRefsCollection } from '@deleteagency/dc/src/dc-dom';
import { IBurgerLink } from 'components/burger-menu/js/types';
import { BurgerMenuModal } from 'components/burger-menu/js/burger-menu-modal';
import { renderContainer, unmountModal } from 'components/modal/js/render-container';

const MODAL_IS_ACTIVE_CLASS = 'is-active';
const MENU_IS_OPEN_BODY_CLASS = 'is-menu-open';
export interface IOptions {
    items?: IBurgerLink[];
    texts?: string[];
    subscribeLink?: string;
    nightfrankLink?: string;
}

interface IRefs extends IDCRefsCollection {
    btn: HTMLElement;
    //contents: HTMLElement[];
}

class BurgerMenuComponent extends DcBaseComponent<HTMLDivElement, IOptions, IRefs> {
    static namespace = 'burger-menu';

    static requiredRefs = ['btn'];

    _isOpen = false;

    init() {
        this.addListener(this.refs.btn, 'click', () => this._onClick());
    }

    _onClick = () => {
        if (this._isOpen) {
            document.querySelector('.modal')?.classList.remove('animate');
            setTimeout(() => {
                unmountModal();
                this._isOpen = false;
                this.refs.btn.classList.remove(MODAL_IS_ACTIVE_CLASS);
                document.body.classList.remove(MENU_IS_OPEN_BODY_CLASS);
            }, 500);
        } else {
            if (this.options.items) {
                render(
                    createElement(
                        BurgerMenuModal,
                        { items: this.options.items, texts: this.options.texts, subscribeLink: this.options.subscribeLink, nightfrankLink: this.options.nightfrankLink},
                        null
                    ),
                    renderContainer
                );
                this._isOpen = true;
                this.refs.btn.classList.add(MODAL_IS_ACTIVE_CLASS);
                document.body.classList.add(MENU_IS_OPEN_BODY_CLASS);
            } else {
                console.warn('items for burger menu has not been specified');
            }
        }
    };
}

export default BurgerMenuComponent;
