import { DcBaseComponent } from '@deleteagency/dc';
import { render } from 'react-dom';
import { createElement } from 'react';
import { IBasicVideoSettings } from 'components/video/js/video-creator';

import { VideoModal } from 'components/video/js/video-modal';

class VideoComponent extends DcBaseComponent<HTMLDivElement, IBasicVideoSettings> {
    static namespace = 'video-section';

    init() {
        const options = this.options;
        const box = this.element.querySelector('.video');
        box?.classList.remove('video');

        render(createElement(VideoModal, { ...options }), box);
    }
}

export default VideoComponent;
