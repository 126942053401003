import { DcBaseComponent } from '@deleteagency/dc';
import { IDCRefsCollection } from '@deleteagency/dc/src/dc-dom';
import Choices from 'choices.js';

interface IRefs extends IDCRefsCollection {
    selectitem: HTMLElement;
}

export interface IOptions {}

class SelectSpecComponent extends DcBaseComponent<HTMLDivElement, IOptions, IRefs> {
    static namespace = 'select-spec';

    init() {
        // @ts-ignore
        const choices = new Choices(this.element, {
            searchEnabled: false,
        });
    }
}

export default SelectSpecComponent;
