import React, { HTMLAttributes, useLayoutEffect } from 'react';
import classnames from 'classnames';
import { dcFactory } from '@deleteagency/dc';
import { Container } from 'components/container/js/container';
import { Section } from 'components/section/js/section';
import { GenericCard, IGenericCard } from 'components/generic-card/js/generic-card';
import { ButtonPrevArrow } from 'components/buttons/js/button-prev-arrow';
import { ButtonNextArrow } from 'components/buttons/js/button-next-arrow';

export const GenericSlider = ({
    className,
    items,
    title,
    isWithoutFilters,
    isWithFilters,
    isImageGallery,
    ...props
}: IGenericSlider): JSX.Element => {
    useLayoutEffect(() => {
        dcFactory.init();
    }, []);

    return (
        <Section
            className={classnames(
                className,
                'generic-slider',
                { 'generic-slider--without-filters': isWithoutFilters },
                { 'generic-slider--with-filters': isWithFilters },
                { 'generic-slider--image-gallery': isImageGallery }
            )}
            data-dc-generic-slider
            {...props}
        >
            <Container className="generic-slider__inner">
                <div className="generic-slider__head">
                    {title && <h2 className="generic-slider__title">{title}</h2>}
                    <div data-glide-el="controls" className="generic-slider__controls">
                        <ButtonPrevArrow aria-label="previous" data-glide-dir="<" />
                        <ButtonNextArrow aria-label="next" data-glide-dir=">" />
                    </div>
                </div>
                <div
                    className="generic-slider__slider-container"
                    data-dc-generic-slider-ref="trackWrapper"
                    data-glide-el="track"
                >
                    <ul className="generic-slider__list">
                        {items.map((item, index) => (
                            <li
                                key={'generic-slider-card_' + index}
                                className="generic-slider__card"
                                data-dc-generic-slider-ref="cards[]"
                            >
                                <GenericCard {...item} />
                            </li>
                        ))}
                    </ul>
                </div>
            </Container>
        </Section>
    );
};

interface IGenericSlider extends HTMLAttributes<HTMLDivElement> {
    title?: string;
    items: IGenericCard[];
    isWithoutFilters?: boolean;
    isWithFilters?: boolean;
    isImageGallery?: boolean;
}
