//import DcExpandGlideSlider from 'components/slider/js/dc-expand-glide-slider';
import DcExpandTinySlider from 'components/slider/js/dc-expand-tiny-slider';

class ImageGalleryComponent extends DcExpandTinySlider<HTMLElement, void, void> {
    static namespace = 'image-gallery';

    init() {
        this.tinyOptions.gutter = 0;
        this.sliderInit();
    }
}

export default ImageGalleryComponent;
