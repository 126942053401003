import { DcBaseComponent } from '@deleteagency/dc';
import { IDCRefsCollection } from '@deleteagency/dc/src/dc-dom';

interface IRefs extends IDCRefsCollection {
    inputs: HTMLInputElement[];
    selects: HTMLSelectElement[];
    submitBtn: HTMLElement;
}

export interface IOptions {}

class SelectSpecComponent extends DcBaseComponent<HTMLFormElement, IOptions, IRefs> {
    static namespace = 'form-validate';

    inputs = this.refs?.inputs || [];

    selects = this.refs?.selects || [];

    submitBtn = this.refs?.submitBtn;

    allInputs = [...this.inputs, ...this.selects];

    isFormValid = true;

    init() {
        this.element.noValidate = true;
        this.addListener(this.element, 'submit', (e: Event) => this._onSubmit(e));

        this.allInputs.forEach((input) => {
            this.addListener( input, 'change', (e: Event) => this.checkFieldValidity(e.target as HTMLInputElement));
        });

        this.inputs.forEach((input) => {
            this.addListener( input, 'input', (e: Event) => this.checkFieldValidity(e.target as HTMLInputElement));
        });
    }

    _onSubmit = (e: Event) => {
        e.preventDefault();
        this.isFormValid = true;
        this.allInputs.forEach((input) => {
            this.checkFieldValidity(input);
        });

        if (this.isFormValid) this.element.submit();
    }

    checkFieldValidity(input: HTMLInputElement | HTMLSelectElement) {
        const wrapper = input.closest('label');
        const messageArea = wrapper?.querySelector(
            '[data-dc-form-validate-ref="message-area"]'
        );

        if (!messageArea) return;

        if (!input.checkValidity()) {
            const message = input.getAttribute('data-dc-form-validate-message');
            messageArea.textContent = message;
            wrapper?.classList.add('is-error');
            this.isFormValid = false;
        } else {
            messageArea.textContent = '';
            wrapper?.classList.remove('is-error');
        }
    }
}

export default SelectSpecComponent;
