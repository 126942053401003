import React, { useMemo, useState } from 'react';
import { IGenericCard } from 'components/generic-card/js/generic-card';
import { CarouselFilters } from './carousel-filters';
import { CarouselItems } from './carousel-items';

export const CarouselWithFilters = ({
    items,
    title,
}: {
    items: IGenericCard[];
    title: string;
}): JSX.Element => {
    const [selectedFilter, setSelectedFilter] = useState<string | null>(null);
    const filteredItems = useMemo(
        () => items.filter((item) => !selectedFilter || item.filterType === selectedFilter),
        [items, selectedFilter]
    );

    return (
        <div className="carousel-with-filters">
            <div className="carousel-with-filters__inner">
                <CarouselFilters
                    filters={
                        [
                            ...new Set(
                                items
                                    .map(({ filterType }) => filterType)
                                    .filter((filterType) => !!filterType)
                            ),
                        ] as string[]
                    }
                    title={title}
                    onFilterChange={(newFilter) => setSelectedFilter(newFilter)}
                />
                <CarouselItems items={filteredItems} />
            </div>
        </div>
    );
};
