import React, { ReactNode } from 'react';
import classnames from 'classnames';
import { IArticleCard } from 'general/types/api-types';

export const ArticleCard = ({
    link,
    className,
    imgSrcset,
    title,
    label,
    children,
    themeName,
}: IArticleCardComponent): JSX.Element => {
    return (
        <a
            href={link}
            className={classnames(className, 'article-card', { [`theme-${themeName}`]: themeName })}
        >
            <div className="article-card__media">
                <img className="lazyload" data-srcset={imgSrcset} alt={title} />
            </div>
            <div className="article-card__content">
                <p className="article-card__label">{label}</p>
                <h3 className="article-card__title">{title}</h3>
                {children}
            </div>
        </a>
    );
};

interface IArticleCardComponent extends IArticleCard {
    className?: string;
    children?: ReactNode;
}
