import React, { HTMLProps, ReactNode } from 'react';
import classNames from 'classnames';

export const ButtonLink = ({ className, href, children, ...props }: IButton): JSX.Element => {
    return (
        <a href={href} {...props} className={classNames('btn', 'btn-animated' className)}>
            <span className="btn-animated__span">
                <span className="btn-animated__span-inner">
                    {children}
                </span>
                <span className="btn-animated__span-inner">
                    {children}
                </span>
            </span>
        </a>
    );
};

type IButton = HTMLProps<HTMLAnchorElement> & {
    href?: string;
    children?: ReactNode;
};
