import { IGenericCard } from 'components/generic-card/js/generic-card';
import { GenericSlider } from 'components/generic-slider/js/generic-slider';
import React, { useLayoutEffect, useState } from 'react';

export const CarouselItems = ({ items }: { items: IGenericCard[] }): JSX.Element => {
    const [flagToRender, setFlagToRender] = useState(false);

    useLayoutEffect(() => {
        setFlagToRender((oldFlag) => !oldFlag);
    }, [items]);

    return (
        <>
            {flagToRender && <GenericSlider items={items} title={'Title'} isWithFilters />}
            {!flagToRender && <GenericSlider items={items} title={'Title'} isWithFilters />}
        </>
    );
};
