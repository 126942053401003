import React from 'react';
import classnames from 'classnames';
import { ISimpleLink } from 'general/types/link';

export const GenericCard = ({
    srcSet,
    subtitle,
    title,
    supText,
    link,
}: IGenericCard): JSX.Element => {
    return (
        <a href={link?.url} target={link?.target} className={classnames('generic-card')}>
            {srcSet && (
                <div className="generic-card__media">
                    <img className="lazyload" data-srcset={srcSet} data-sizes="auto" alt={title} />
                </div>
            )}
            <div className="generic-card__content">
                {subtitle && <h2 className="generic-card__subtitle">{subtitle}</h2>}
                {title && <h3 className="generic-card__title">{title}</h3>}
                {supText && <p className="generic-card__sup">{supText}</p>}
            </div>
        </a>
    );
};

export interface IGenericCard {
    srcSet?: string;
    subtitle?: string;
    title?: string;
    supText?: string;
    link?: ISimpleLink;
    filterType?: string;
}
