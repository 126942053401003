import { DcBaseComponent } from '@deleteagency/dc';

class BackToTop extends DcBaseComponent<HTMLDivElement> {
    static namespace = 'back-to-top';

    init() {
        this.addListener(this.element, 'click', () => this._onClick());
    }

    _onClick = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }
}

export default BackToTop;
