import { createElement } from 'react';
import { render } from 'react-dom';
import { DcBaseComponent } from '@deleteagency/dc';
import { ArticlesListContent } from './articles-list-content';
import { IArticleCard } from 'general/types/api-types';
export interface IOptions {
    items?: IArticleCard[];
    isLoadMoreVisible: boolean;
    endPoint: string;
    categoryTitle?: string;
}
class ArticlesListComponent extends DcBaseComponent<HTMLDivElement, IOptions> {
    static namespace = 'articles-list';

    init() {
        if (this.options.items) {
            render(
                createElement(
                    ArticlesListContent,
                    {
                        initialItems: this.options.items,
                        isLoadMoreVisibleInitial: this.options.isLoadMoreVisible,
                        endPoint: this.options.endPoint,
                        categoryTitle: this.options.categoryTitle,
                    },
                    null
                ),
                this.element
            );

        } else {
            console.warn('items for Articles list have not been specified');
        }
    }
}

export default ArticlesListComponent;

