import {
    twitterShare,
    copyToClipboard,
    linkedinShare,
    facebookShare,
} from 'components/share/js/share-helpers';

// TODO: refactor without any
export const shareListConfig: any = Object.freeze({
    twitter: {
        name: 'twitter',
        type: 'button',
        handleClick: () => twitterShare(),
        iconSizes: [23, 18],
        iconName: 'twitter',
        label: 'share on twitter',
        text: 'Twitter',
    },
    downloadLink: {
        name: 'downloadLink',
        type: 'button',
        iconSizes: [24, 24],
        iconName: 'download',
        label: 'download material',
        text: 'Download link',
    },
    linkedin: {
        name: 'linkedn',
        type: 'button',
        handleClick: () => linkedinShare(),
        iconSizes: [22, 22],
        iconName: 'linkedin',
        label: 'share on linkedIn',
        text: 'LinkedIn',
    },
    facebook: {
        name: 'facebook',
        type: 'button',
        handleClick: () => facebookShare(),
        iconSizes: [11, 26],
        iconName: 'facebook',
        label: 'share on facebook',
        text: 'Facebook',
    },
    copyLink: {
        name: 'copyLink',
        type: 'button',
        handleClick: () => copyToClipboard(),
        iconSizes: [24, 24],
        iconName: 'link',
        label: 'copy link',
        text: 'Copy link',
    },
    embed: {
        name: 'embed',
        type: 'button',
        iconSizes: [24, 24],
        handleClick: () => copyToClipboard(),
        iconName: 'embed',
        label: 'copy embed code',
        text: 'copy embed code',
    },
    email: {
        name: 'email',
        type: 'button',
        iconSizes: [24, 24],
        iconName: 'email',
        label: 'share via email',
        text: 'share',
    },
});
