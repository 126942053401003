import React, { useMemo } from 'react';
import { nanoid } from 'nanoid';
import { Modal } from 'components/modal/js/modal';
import { IBurgerLink } from 'components/burger-menu/js/types';
import { unmountModal } from 'components/modal/js/render-container';
import { SubscribeLink } from 'components/_sidebar/js/subscribe-link';

export const BurgerMenuModal = ({ items, texts, subscribeLink, nightfrankLink }: IBurgerMenu): JSX.Element => {
    const id = useMemo(() => nanoid(10), []);
    return (
        <Modal closeCb={unmountModal} modifiers={['burger']} id="burger" hasDefaultClose={false}>
            <div className="burger-menu">
                <ol className="burger-menu__list">
                    {items.map(({ text, url }, index) => (
                        <li key={`${id}_${index}`} className="burger-menu__item">
                            <a
                                className="burger-menu__link"
                                href={url}
                            >
                                <span className="burger-menu__title">
                                    <span className="burger-menu__title-inner">{text}
                                    </span>
                                    <span className="burger-menu__title-inner">{text}
                                    </span>
                                </span>
                            </a>
                        </li>
                    ))}
                </ol>
            </div>

            <div className="burger-menu__footer">
                
                <div className="burger-menu__logo-subscribe">
                    {subscribeLink ? (
                        <SubscribeLink
                            className="subscribe-link--footer"
                            link={subscribeLink} />
                    ) : null}

                    {nightfrankLink ? (
                        <a href="https://www.knightfrank.com/" title="Knight Frank">
                            <svg className="icon sitelogo__icon" width="44" height="44" focusable="false">
                                <use xlinkHref="#icon-kf-logo"></use>
                            </svg>
                        </a>
                    ) : null}
                </div>
                {texts ? (
                    <div className="burger-menu__content-wrapper">
                        <ul className="burger-menu__content">
                            {texts?.map((item, index) => (
                                <li key={`${id}_${index}`} className="burger-menu__content-item">
                                    {item}
                                </li>
                            ))}
                        </ul>
                    </div>
                ) : null}
            </div>
        </Modal>
    );
};

interface IBurgerMenu {
    items: IBurgerLink[];
    texts?: string[];
    subscribeLink?: string;
    nightfrankLink?: string;
}
