const windowOpen = (url: string) => {
    const width = 500;
    const height = 400;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;

    window.open(
        url,
        '',
        `menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=${width},height=${height},top=${top},left=${left}`
    );
};

export const twitterShare = () => {
    windowOpen('http://twitter.com/share?url=' + window.location.href);
};

export const copyToClipboard = () => {
    window.navigator.clipboard.writeText(window.location.href);
};

export const linkedinShare = () => {
    windowOpen('https://www.linkedin.com/sharing/share-offsite/?url=' + window.location.href);
};

export const facebookShare = () => {
    windowOpen('https://www.facebook.com/sharer/sharer.php?u=' + window.location.href);
};
