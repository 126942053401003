import React from 'react';
import classnames from 'classnames';

export const SubscribeLink = ({
    className, 
    link
}:ISubscribeLink): JSX.Element => {
    return (
        <a href={link} 
        className={classnames(className, 'subscribe-link')}>
            <svg className="icon subscribe-link__black" width="27" height="27" focusable="false">
                <use xlinkHref="#icon-subscribe"></use>
            </svg>
            <span>Subscribe</span>
        </a>
    );
};

interface ISubscribeLink {
    className: string;
    link: string;
}
