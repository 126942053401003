import React, { useState } from 'react';
import { Modal } from 'components/modal/js/modal';
import { unmountModal } from 'components/modal/js/render-container';
import classnames from 'classnames';
import { LazyImage } from 'general/js/lazy-image';
import { Video } from './video';
import { videoTypes } from './video-creator';

export const VideoModal = ({
    url,
    id,
    thumbnailSrcSet,
    description,
    type,
}: {
    url?: string;
    thumbnailSrcSet?: string;
    description: string;
    type: videoTypes;
    id: string;
}): JSX.Element => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <>
            {isModalOpen && (
                <Modal
                    closeCb={() => {
                        unmountModal();
                        setIsModalOpen(false);
                    }}
                    id="video"
                    modifiers={['video']}
                    hasDefaultClose={false}
                >
                    <button
                        className="video-modal-close"
                        onClick={() => {
                            unmountModal();
                            setIsModalOpen(false);
                        }}>
                        <svg className="icon video-modal-close__icon" width="30" height="30" focusable="false"><use xlinkHref="#icon-cross"></use></svg>
                    </button>
                    <Video
                        thumbnailSrcSet={thumbnailSrcSet}
                        className="video-in-modal"
                        type={type}
                        id={id}
                        url={url}
                        description={description}
                    />
                    <div className="video-description">{description}</div>
                </Modal>
            )}

            <div className="video">
                {thumbnailSrcSet && (
                    <LazyImage src={thumbnailSrcSet} className={classnames('video__media is-play')} />
                )}
                <>
                    <button
                        type="button"
                        className="video__fullbleed-btn test-modal"
                        onClick={() => {
                            setIsModalOpen(true);
                        }}
                    >
                        <span className="visually-hidden">play video</span>
                    </button>
                    <div className="btn-round btn-round--white video__fake-btn">
                        <span>Play</span>
                    </div>
                </>
            </div>
        </>
    );
};
