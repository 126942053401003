import React from 'react';
import { IShareList, IShareItem } from 'components/share/js/types';
import classNames from 'classnames';
import { SvgIcon } from 'general/js/svg-icon';
import { shareListConfig } from 'components/share/js/share.config';

// TODO: refactor without any
const ShareItem = ({ name, link, baseClassName, contentType }: any): JSX.Element | null => {
    const shareItemConfig = shareListConfig[name];

    if (!shareItemConfig) {
        console.warn(`No share config for ${name}`);
        return null;
    }

    const { iconName, handleClick, label, iconSizes, type } = shareItemConfig;
   
    const Content =
        contentType === 'icon' ? (
            <SvgIcon size={iconSizes} iconName={iconName} />
        ) : (
            shareItemConfig.text
        );

    return (
        <li className={`${baseClassName}__item`}>
            {type === 'link' && (
                <a
                    className={`${baseClassName}__btn ${baseClassName}__btn--${name}`}
                    href={link}
                    aria-label={label}
                    target="_blank"
                    rel="noreferrer"
                >
                    {Content}
                </a>
            )}
            {type === 'button' && (
                <a
                    className={`${baseClassName}__btn ${baseClassName}__btn--${name}`}
                    onClick={() => handleClick(link)}
                    aria-label={label}
                >
                    {Content}
                </a>
            )}
        </li>
    );
};

export const ShareList = ({
    baseClassName = 'share-list',
    shareItems,
    className,
    contentType = 'text',
}: IShareList): JSX.Element => {
    return (
        <ul className={classNames(baseClassName, className)}>
            {shareItems.map(({ name, link }: IShareItem) => {
                return (
                    <ShareItem
                        key={name}
                        contentType={contentType}
                        name={name}
                        link={link}
                        baseClassName={baseClassName}
                    />
                );
            })}
        </ul>
    );
};
