import { DcBaseComponent } from '@deleteagency/dc';

class AnimateComponent extends DcBaseComponent<HTMLDivElement> {
    static namespace = 'animate';

    init() {

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    this.element.classList.add('anim-load__ready');
                    observer.unobserve(this.element);
                }
            });
        });

        observer.observe(this.element);
    }
}

export default AnimateComponent;
