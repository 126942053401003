import React, { useState } from 'react';
import classnames from 'classnames';

export const CarouselFilters = ({
    filters,
    title,
    onFilterChange,
}: {
    filters: string[];
    title: string;
    onFilterChange: (newFilter: string | null) => unknown;
}): JSX.Element => {
    const [selectedFilter, setSelectedFilter] = useState<string | null>(null);

    return (
        <div className="carousel-filters container">
            <h2 className="carousel-filters__title">{title}</h2>
            <ul className="carousel-filters__list">
                {filters.map((filter, index) => (
                    <button
                        className={classnames('carousel-filters__filter', {
                            'carousel-filters__filter--inactive':
                                selectedFilter !== filter && selectedFilter,
                        })}
                        type="button"
                        key={index}
                        onClick={() => {
                            const newFilter = selectedFilter === filter ? null : filter;

                            setSelectedFilter(newFilter);
                            onFilterChange(newFilter);
                        }}
                    >
                        {filter}
                    </button>
                ))}
            </ul>
        </div>
    );
};
