import React, { useMemo, useState } from 'react';
import { Container } from 'components/container/js/container';
import { IArticleCard, IApiResponse } from 'general/types/api-types';
import { ArticleCard } from 'components/article-card/js/article-card';
import { nanoid } from 'nanoid';
import axios, { AxiosResponse } from 'axios';
import { AnimatedList } from 'react-animated-list';
import { ButtonLink } from 'components/buttons/js/button-link';

export const ArticlesListContent = ({
    initialItems,
    isLoadMoreVisibleInitial,
    endPoint,
    categoryTitle,
}: IArticleListContent): JSX.Element => {

    const [articlesState, setState] = useState({
        items: initialItems,
        page: 0,
        isLoadMoreVisible: isLoadMoreVisibleInitial,
        isLoading: false,
    });
    const { items, page, isLoadMoreVisible, isLoading } = articlesState;

    const id = useMemo(() => nanoid(10), []);
    const api = axios.create({ timeout: 120000 });
    let axiosSource = axios.CancelToken.source();
    const cancelRequest = (reason = 'user interacted') => {
        axiosSource.cancel(reason);
        axiosSource = axios.CancelToken.source();
    };

    const _onClick = () => {
        if (!isLoadMoreVisible) return;
        if (isLoading) cancelRequest();
        sendRequest();
    };

    const sendRequest = () => {
        setState({
            ...articlesState,
            isLoading: true,
        });
        api.get(`${endPoint}?page=${page}`, {
            cancelToken: axiosSource.token,
        })
            .then(({ data }: AxiosResponse<IApiResponse>) => {
                setState({
                    ...articlesState,
                    items: [...items, ...data.items],
                    isLoadMoreVisible: data.isLoadMoreVisible,
                    isLoading: false,
                    page: data.page,
                });
            })
            .catch((error: any) => {
                setState({
                    ...articlesState,
                    isLoading: false,
                });
                console.dir(error);
            });
    };

    return (
        <Container>
            {categoryTitle && (
                <div className="articles-list-title">
                    <h2 className="articles-list-title__text">
                        Explore articles in {categoryTitle}
                    </h2>
                </div>
            )}

            <ul className="articles-list">
                <AnimatedList animation={'grow'}>
                    {items.map((item, index) => (
                        <li key={`${id}_${index}`} className="articles-list__item">
                            <ArticleCard {...item} />
                        </li>
                    ))}
                </AnimatedList>
            </ul>

            {isLoadMoreVisible && (
                <div className="articles-list__btn-wrapper">
                    <ButtonLink
                        onClick={() => {
                            _onClick();
                        }}
                        className="articles-list__btn"
                    >
                        Load More Articles
                    </ButtonLink>
                </div>
            )}
        </Container>
    );
};

interface IArticleListContent {
    initialItems: IArticleCard[];
    isLoadMoreVisibleInitial: boolean;
    endPoint: string;
    categoryTitle?: string;
}
