import React, { HTMLAttributes } from 'react';
import classNames from 'classnames';

export const Section = ({
    children,
    className,
    ...props
}: HTMLAttributes<HTMLElement>): JSX.Element => {
    return (
        <section className={classNames(['section', className])} {...props}>
            {children}
        </section>
    );
};
