import React, { HTMLAttributes, ReactElement } from 'react';
import { VideoYoutube } from './video-youtube';
import { VideoHTML5 } from './video-html5';
import { VideoVimeo } from './video-vimeo';

// eslint-disable-next-line no-shadow
export enum videoTypes {
    YOUTUBE = 'youtube',
    VIMEO = 'vimeo',
    HTML5 = 'html5',
}

export const VideoCreator = (
    props: TypicalVideoProps & HTMLAttributes<HTMLElement>
): ReactElement | null => {
    switch (props.type) {
        case videoTypes.YOUTUBE:
            return <VideoYoutube {...props} />;
        case videoTypes.VIMEO:
            return <VideoVimeo {...props} />;
        case videoTypes.HTML5:
            return <VideoHTML5 {...props} />;
        default:
            return null;
    }
};

export interface IBasicVideoSettings {
    type: videoTypes;
    id: string;
    description: string;
    url?: string;
    thumbnailSrcSet?: string;
}

export interface TypicalVideoProps extends IBasicVideoSettings {
    id: string;
    isActive: boolean;
    controls?: boolean;
    repeat?: boolean;
    aspectRatio?: string;
    mute?: boolean;
    onReady?: () => void;
}
