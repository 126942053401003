import { DcBaseComponent } from '@deleteagency/dc';
import { IDCRefsCollection } from '@deleteagency/dc/src/dc-dom';
// @ts-ignore
import {tns} from 'src/general/js/_tiny/tiny-slider';
//import { deviceObserver } from 'general/js/device-observer';

export interface ISliderRefs extends IDCRefsCollection {
    cards: HTMLElement[];
    container: HTMLElement;
    controlsContainer: HTMLElement;
}

interface ITinyOptions {
    loop: boolean,
    rewind: boolean;
    autoplay: number | boolean;
    animateNormal: string;
    responsive: boolean | object;
    gutter: (() => number) | number;
    items: number;
    mode: string;
    edgePadding: number;
    nav: boolean;
    prevButton: boolean;
    nextButton: boolean;
    controlsContainer: HTMLElement | boolean;
    container: HTMLElement;
    autoWidth: boolean;
    speed: number;
    mouseDrag: boolean;
}

export default class DcExpandTinySlider<
    T extends HTMLElement,
    O = void,
    R extends void | ISliderRefs = void
    > extends DcBaseComponent<T, O, R> {
    public slider: any;

    public cards: HTMLElement[];

    public container: HTMLElement;

    public controlsContainer: HTMLElement;

    public tinyOptions: ITinyOptions;

    private unsubscribeResize?: CallableFunction | null;

    constructor(element: T, namespace: string, refs: string[]) {
        super(element, namespace, refs);

        this.cards = this.refs?.cards || [];
        // @ts-ignore
        this.container = this.refs?.list || [];
        // @ts-ignore
        this.controlsContainer = this.refs?.controls[0] || [];

        //this.sliderList = this.refs?.sliderList|| [];
        this.tinyOptions = {
            container: this.container,
            loop: true,
            rewind: false,
            autoplay: false,
            animateNormal: 'tns-normal',
            responsive: false,
            gutter: 0,
            items: 3,
            mode: 'carousel',
            edgePadding: 0,
            nav: false,
            prevButton: false,
            nextButton: true,
            controlsContainer: this.controlsContainer,
            autoWidth: true,
            speed: 300,
            mouseDrag: true,
        };
        //this.trackWrapper = this.refs?.trackWrapper || this.element;
        //this.sliderList = this.refs?.sliderList|| [];

    }

    onInit() {
        this.sliderInit();
    }

    onSliderInit = () => {};

    onSliderDestroy = () => {};

    public sliderInit = () => {
        //this.unsubscribeResize = deviceObserver.subscribeOnResize(this._calculate);
        this._initSlider();
    };

    private _initSlider = () => {
        this.element.classList.add('is-inited');
        this.slider = new tns(this.tinyOptions);
        this.onSliderInit();
    };

    _destroySlider = () => {
        if (this.slider) {
            this.element.classList.remove('is-inited');
            this.slider.destroy();
            this.slider = null;
            this.onSliderDestroy();
        }
    };

    destroy = () => {
        if (this.unsubscribeResize) this.unsubscribeResize();
        this.unsubscribeResize = null;
        this._destroySlider();
    };

    onDestroy = () => {
        this.destroy();
    };
}
