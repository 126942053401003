import { createElement } from 'react';
import { render } from 'react-dom';
import { DcBaseComponent } from '@deleteagency/dc';
import { CarouselWithFilters } from 'components/carousel-filter/js/carousel-with-filters';
import { IGenericCard } from 'components/generic-card/js/generic-card';

export interface IOptions {
    items?: IGenericCard[];
    title: string;
}

class CarouselFilter extends DcBaseComponent<HTMLDivElement, IOptions, void> {
    static namespace = 'carousel-filter';

    init() {
        if (this.options.items) {
            render(
                createElement(
                    CarouselWithFilters,
                    { items: this.options.items, title: this.options.title },
                    null
                ),
                this.element
            );
        }
    }
}

export default CarouselFilter;
